<template>
  <v-card>
    <v-card-title class="text-h6 mb-5">{{
      $t("labels.delivery_contract")
    }}</v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          v-if="updatingItem && updatingItem.id"
          class="font-weight-medium fs-16px"
        >
          <span v-if="updatingItem.warehouse_code"
            >{{ $t("labels.warehouse_1") }}:
            {{ updatingItem.warehouse_code }}</span
          >
          <span v-if="updatingItem.pos_code"
            >{{ $t("labels.pos") }}: {{ updatingItem.pos_code }}</span
          >
        </v-col>
        <template v-else>
          <v-col cols="6">
            <select-warehouse-model
              v-model="idWarehouse"
              :disabled="!!idPos"
              :single-line="false"
              :label="$t('labels.warehouse_1')"
              clearable
            ></select-warehouse-model>
          </v-col>
          <v-col cols="6">
            <select-pos-model
              v-model="idPos"
              :disabled="!!idWarehouse"
              :single-line="false"
              :label="$t('labels.pos')"
              clearable
            ></select-pos-model>
          </v-col>
        </template>

        <v-col cols="12">
          <v-autocomplete
            class="c-input-xs"
            v-model="idDeliveryCompany"
            :items="allowDeliveryCompanies"
            :label="$t('labels.delivery_company')"
            :placeholder="$t('labels.delivery_company')"
            dense
            outlined
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" v-for="field in allowFields" :key="`f_${field}`">
          <v-text-field
            class="c-input-xs"
            v-model="accountInfo[field]"
            :label="$t(`labels.${field}`)"
            :placeholder="$t(`labels.${field}`)"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="confirm">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  ALLOW_FIELD_DELIVERY_ACCOUNT_GHN,
  ALLOW_FIELD_DELIVERY_ACCOUNT_GHTK,
  ALLOW_FIELD_DELIVERY_ACCOUNT_JNT,
  ALLOW_FIELD_DELIVERY_ACCOUNT_SPX,
  ALLOW_FIELD_DELIVERY_ACCOUNT_VNP,
  ALLOW_FIELD_DELIVERY_ACCOUNT_VTP,
  ALLOW_FIELD_DELIVERY_ACCOUNT_NJV,
  ID_DELIVERY_GHN,
  ID_DELIVERY_GHTK,
  ID_DELIVERY_JNT,
  ID_DELIVERY_SPX,
  ID_DELIVERY_VTP,
  ID_DELIVERY_VNP,
  ID_DELIVERY_NJV,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "DeliveryContract",
  components: {},
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    warehouseOptions: [],
    idWarehouse: null,
    idPos: null,
    idDeliveryCompany: null,
    accountInfo: {
      token: null,
      username: null,
      password: null,
      company_id: null,
      customer_id: null,
      shop_id: null,
      service: null,
      app_id: null,
      app_secret: null,
      user_id: null,
      user_secret: null,
    },
  }),
  computed: {
    allowFields() {
      switch (this.idDeliveryCompany) {
        case ID_DELIVERY_GHN:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_GHN;
        case ID_DELIVERY_GHTK:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_GHTK;
        case ID_DELIVERY_VNP:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_VNP;
        case ID_DELIVERY_JNT:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_JNT;
        case ID_DELIVERY_SPX:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_SPX;
        case ID_DELIVERY_VTP:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_VTP;
        case ID_DELIVERY_NJV:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_NJV;
        default:
          return [];
      }
    },
    allowDeliveryCompanies() {
      return [
        {
          value: ID_DELIVERY_GHN,
          text: "GHN",
        },
        {
          value: ID_DELIVERY_GHTK,
          text: "GHTK",
        },
        {
          value: ID_DELIVERY_VNP,
          text: "VNPost",
        },
        {
          value: ID_DELIVERY_JNT,
          text: "JnT",
        },
        {
          value: ID_DELIVERY_SPX,
          text: "SPX",
        },
        {
          value: ID_DELIVERY_VTP,
          text: "VIETTEL",
        },
        {
          value: ID_DELIVERY_NJV,
          text: "Ninjavan",
        },
      ];
    },
  },
  created() {
    if (this.updatingItem && this.updatingItem.id_delivery_company) {
      this.idDeliveryCompany = this.updatingItem.id_delivery_company;
      this.idWarehouse = this.updatingItem.id_warehouse;
      this.idPos = this.updatingItem.id_pos;
      const account = this.updatingItem.account_info
        ? JSON.parse(this.updatingItem.account_info)
        : {};
      this.accountInfo = { ...account };
    }
    this.getWarehouses();
  },
  methods: {
    getWarehouses() {
      const { warehouses } = window.me;
      this.warehouseOptions = [...warehouses].map((w) => ({
        text: w.code_name,
        value: w.id,
      }));
    },
    cancel() {
      this.$emit("cancel");
    },
    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-contract", {
          id: (this.updatingItem && this.updatingItem.id) || null,
          id_delivery_company: this.idDeliveryCompany,
          id_warehouse: this.idWarehouse,
          id_pos: this.idPos,
          account_info: this.accountInfo,
          status: 1,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
